                     /* ..................................Email Page CSS */
body {
  overflow: auto;}
.backgroundContainer {
  height: 100vh;
  background-color: #d8d6d6;;}
.customBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  max-width: 600px;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 10px 20px;}
.customBox .customTypography {
  text-align: center;}
.customTypography {
  color: #23205d;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;}
                /* Boq PAGE Css .............................................................*/
.custom-form {
  margin-top: 10px;
  padding: 10px;}
.custom-typography {
  margin-top: 20px;
  color: #23205d;}
               /* Table items Css.................................................. */
.TableItems-container {
  text-align: center;
  border: 2px solid gray;
  border-radius: 12px;
  background-color: white;
  padding: 20px;
  margin-top: 30px;}
.TableItems-heading {
  color: black;}
.TableItems-paper {
  width: 90%;
  margin-left: 5%;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 4rem;}
.TableItems-table-container {
  width: 90%;
  background-color: lightgray;}
.ItemName {
  height: auto;
  width: 17rem;
  text-align: left !important;
  padding-left: 7px !important ; }
.itemDescriptionx {
  height: auto;
  text-align: left !important;
  padding-left: 7px !important;}
.TableItems-table {
  background-color: white;
  box-sizing: border-box;
  min-width: 650px;
  border-collapse: collapse;
  font-size: 14px;
  width: 30%;}
.TableItems-table-head {
  & > .MuiTableRow-root {
    &:last-child td,
    &:last-child th {
      border: 1px solid black;
    }
    background-color: black;
    height: 30px; 
  }
& .MuiTableCell-root {
    background-color: #23205d;
    color: white;
    border: 1px solid black;
    border-bottom: 2px solid black;
    padding: 0;
    height: 10px;
    font-weight: bold;
    text-align: center;
  }
  & .MuiTableCell-root + .MuiTableCell-root {
    border-left: 2px solid black;}}
.TableItems-table-body {
  & > .MuiTableRow-root {
    &:last-child td,
    &:last-child th {
      border: 1;}
    border: 2px solid black;
    height: 10px;}
  & .MuiTableCell-root {
    color: black;
    font-family: sans-serif;
    border: 2px solid black;
    border-bottom: 2px solid black;
    padding: 0;
    text-align: center;}
  & .MuiTableCell-root:nth-child(7) {
    text-align: center;
    background-color: lightgrey;}
  & .MuiTableCell-root:nth-child(7) input {
    background-color: white;
    border: 1px solid black;
    border-radius: 20px;
    color: black;
    text-align: center }}
